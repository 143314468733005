import React, { useState, useEffect } from 'react';
import * as Images from '../../../assets';
import EventForm from './EventForm';

const EventDetailSlider = () => {
    const [myIndex, setMyIndex] = useState(0);

    // useEffect(() => {
    //     const carouselInterval = setInterval(() => {
    //         setMyIndex((prevIndex) => (prevIndex + 1) % 3); 
    //     }, 2000);

    //     return () => clearInterval(carouselInterval);
    // }, []);

    const handlePrevClick = () => {
        setMyIndex((prevIndex) => (prevIndex === 0 ? 2 : prevIndex - 1)); // Assuming 3 images, looping back to the last image if at the first
    };

    const handleNextClick = () => {
        setMyIndex((prevIndex) => (prevIndex + 1) % 3); // Assuming 3 images, looping back to the first image if at the last
    };

    const OverlayText = () => {
        return (

            <div className='flex h-full w-[80%] mx-auto '>
                <div className='flex flex-col justify-center  text-left w-[60%] h-full  text-wrap max-md:w-full'>
                    <div className='text-[32px] font-bold text-wrap max-md:text-lg max-sm:text-base max-xs:text-sm'>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    </div>
                    <div className='text-2xl my-8 font-semibold  text-wrap lg:text-2xl max-lg:my-4 md:text-base max-md:my-2 max-sm:my-2 sm:text-sm xs:text-xs xs:my-2'>
                        By Mr. Paresh Rajapara
                    </div>
                    <div className='text-sm max-sm:text-xs'>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s

                    </div>
                    <div className='text-base flex items-center font-bold mt-8 lg:mt-4 md:my-2 sm:text-xs sm:my-2 xs:mt-1'>
                        <svg
                            className='font-bold '
                            xmlns="http://www.w3.org/2000/svg" version="1.0" width="24.000000pt" height="14.000000pt" viewBox="0 0 256.000000 256.000000" preserveAspectRatio="xMidYMid meet">

                            <g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)" fill="#fff" stroke="none">
                                <path d="M1100 2541 c-339 -74 -600 -322 -692 -656 -17 -66 -21 -107 -21 -230 0 -131 3 -162 27 -249 53 -196 149 -401 304 -647 89 -143 498 -727 522 -746 24 -20 66 -15 89 10 19 21 228 315 392 552 329 475 476 842 456 1139 -26 391 -296 718 -672 817 -116 30 -294 35 -405 10z m327 -147 c151 -31 275 -99 385 -211 133 -137 200 -283 215 -472 19 -251 -111 -578 -400 -1008 -133 -197 -338 -483 -347 -483 -9 0 -214 286 -347 483 -327 485 -445 823 -387 1105 83 404 480 669 881 586z" />
                                <path d="M1175 2096 c-84 -21 -147 -57 -210 -121 -178 -177 -178 -453 0 -630 258 -259 695 -118 757 244 24 141 -22 281 -127 387 -112 113 -269 158 -420 120z m239 -170 c105 -51 161 -144 161 -266 0 -85 -21 -142 -74 -203 -82 -92 -237 -121 -353 -64 -160 79 -215 278 -118 430 80 126 246 170 384 103z" />
                            </g>
                        </svg>
                        <div>View Map</div>
                    </div>

                </div>
            </div>

        )
    }

    return (
        <section className=" flex justify-center items-center px-16 pt-20 max-md:px-5">

            <div className="relative flex flex-col max-w-full w-[1296px]">

                <div className='relative '>

                    <div className="w3-content w3-section relative"
                    // style={{ maxWidth: '500px' }}
                    >

                        <div className='w-full flex justify-between items-center absolute top-[50%] z-10'>
                            <button className="w-fit -ms-4 focus:outline-0 focus:border-0" onClick={handlePrevClick} >
                                <i className="px-4 py-3 fa fa-angle-left rounded-full  bg-white text-orange-500 hover:bg-orange-500 hover:text-white"></i>
                            </button>
                            <button className="w-fit -me-4 focus:outline-0 focus:border-0" onClick={handleNextClick}>
                                <i className="px-4 py-3 fa fa-angle-right rounded-full  bg-white text-orange-500 hover:bg-orange-500 hover:text-white"></i>
                            </button>
                        </div>
                        <div>
                            <div className=''>
                                <img
                                    className="mySlides z-10 "
                                    src={Images.eventdetail}
                                    style={{ width: '100%', display: myIndex === 0 ? 'block' : 'none' }}
                                    alt="Los Angeles"
                                />
                                <div className='bg-black-50 absolute text-white w-full h-full overlay_event_detail rounded-2xl opacity-25'>
                                    <OverlayText />
                                </div>
                            </div>
                            <div className=''>
                                <img
                                    className="mySlides"
                                    src={Images.mmb_module2}
                                    style={{ width: '100%', display: myIndex === 1 ? 'block' : 'none' }}
                                    alt="New York"
                                />
                                <div className='bg-black-50 absolute text-white w-full h-full overlay_event_detail rounded-2xl opacity-25'>
                                    <OverlayText />
                                </div>
                            </div>
                            <div className=''>
                                <img
                                    className="mySlides"
                                    src={Images.mmb_module3}
                                    style={{ width: '100%', display: myIndex === 2 ? 'block' : 'none' }}
                                    alt="Chicago"
                                />
                                <div className='bg-black-50 absolute text-white w-full h-full overlay_event_detail rounded-2xl opacity-25'>
                                    <OverlayText />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='w-[30%] absolute top-[5%] right-[5%]  max-md:relative max-md:w-full max-md:right-0'>
                        <EventForm />
                    </div>
                </div>

            </div>
        </section>
    );
}

export default EventDetailSlider;
