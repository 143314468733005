import React from 'react';
import * as Images from '../../../assets';

const GetInTouchContent = () => {
    return (
        <div
         className=" flex justify-center items-center px-16 py-12 bg-white max-md:px-5" 
         >

            <div className="max-w-full w-[1296px] ">
                <div className="text-start flex flex-col self-stretch">
                    <div className="my-6 w-full text-lg text-zinc-600 max-md:max-w-full">
                        Whether you seek expert coaching to grow your jewellery store or strategic
                        consultation to navigate business transformation, our dedicated team at&nbsp;
                        <span className='text-orange-500'>Headway Business Solutions LLP</span> 
                        &nbsp;is here for you. Reach out through the provided contact
                        form, and let&#39;s embark on a journey of transformation, together. Your success is what
                        matters most to us!
                    </div>
                </div>

            </div>
        </div>
    )
}

export default GetInTouchContent