import React from 'react';
import * as Images from '../../../assets';

const TCContent = () => {
const officeAddress = () => {
    return "101-Shree Kashi Parekh Complex, First Floor, Behind Bhagavati Chambers, Near Swastik Cross Rd, C G Road, Navrangpura, Ahmedabad, Gujarat 380009.";
};
    
    return (
        <div className="relative flex justify-center items-center px-16 py-12 bg-white max-md:px-5" >
            <div className="absolute  bottom-0">
                <img
                    // src={Images.blog_into_bg}
                     src={Images.user_auth_vector}
                    // src={Images.terms_and_cond_content_bg}
                    alt="Images"
                    loading="lazy"
                    className="object-fill full-size"
                />
            </div>
            <div className="text-start z-10 mt-6 max-w-full w-[1296px]">
                <div className="flex flex-col self-stretch text-lg blog_intro">
                    <div className="w-full max-md:max-w-full">
                        Welcome to&nbsp;
                        <span className="text-orange-500">Headway Business Solutions LLP</span>
                        ("Headway," "we," "us," or "our").
                        &nbsp;These Terms and Conditions ("Terms") govern your access to and use of our website (
                        <span className="text-orange-500">www.headway.org.in</span>),
                        mobile application (the "App"), and all associated services (collectively, the "Service").
                        Please read them carefully before proceeding.
                    </div>
                    <div className="mt-8 w-full max-md:max-w-full">
                        These terms and conditions of use (“Terms of Use”) are between the Company, and the User(s),
                        who are registered with us, or who use the Website and/or the App (hereinafter referred to as “You” or
                        “Your” or “ User(s)”).
                    </div>
                    <div className="flex flex-col p-4 mt-8 w-full rounded-xl terms_bg_clr text-zinc-600 max-md:max-w-full">
                        <div className="max-md:max-w-full">
                            Please read these Terms of Service as they shall govern your use of the&nbsp;
                            <span className="text-orange-500">Headway Business Solutions LLP</span>
                            &nbsp;website and app. By using or visiting the&nbsp;
                            <span className="text-orange-500">Headway Business Solutions LLP</span>
                            &nbsp;website, app,
                            or any&nbsp;
                            <span className="text-orange-500">Headway Business Solutions LLP</span>
                            &nbsp;products, software, data feeds, and services provided to you on,
                            from, or through the&nbsp;
                            <span className="text-orange-500">Headway Business Solutions LLP</span>
                            &nbsp;website (collectively the “Service”)
                            you signify your agreement to
                        </div>
                        <div className="mt-6 max-md:max-w-full">
                            1. These “Terms of Service”
                        </div>
                        <div className="mt-2 max-md:max-w-full">
                            2. <span className="text-orange-500">Headway Business Solutions LLP</span>’s Privacy Policy
                        </div>

                        <div className='mt-6'>
                            Found at&nbsp;
                            <span className="text-orange-500">https://headway.org.in/privacy-policy</span>
                            &nbsp;and incorporated herein by
                            reference and any other terms that are updated from time to time. If you do not agree to any of these
                            terms or the Privacy Policy, please do not use this website.

                        </div>
                    </div>
                    <div className="mt-8 w-full max-md:max-w-full">
                        These Terms of Service are published in accordance with the provisions of Rule 3 (1) of the Information Technology
                        (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and terms
                        and conditions for access or usage of the Website and/or App.
                    </div>
                    <div className="mt-8 w-full text-zinc-600 max-md:max-w-full">
                        We may attempt to notify you when major changes are made to these Terms of Service.
                        You should occasionally review the most up-to-date version of&nbsp;
                        <span className="text-orange-500"> www.headway.org.in Headway Business Solutions LLP</span>
                        &nbsp;may, in its sole discretion, modify or revise these Terms of Service and policies at any time, and you agree to be bound by such modifications or revisions.
                    </div>
                    <div className="mt-8 w-full max-md:max-w-full">
                        Your continued use of the Services post any modification of the Terms of Service shall be
                        taken as your consent and acceptance of such modifications. Nothing in these Terms of
                        Service shall be deemed to confer any third-party rights or benefits.
                    </div>
                </div>

                <div className="flex flex-col self-stretch mt-8">
                    <div className="w-full text-[32px] text-zinc-800 max-md:max-w-full font-bold">
                        Ownership  <span className="text-orange-500">and Registration</span>
                        {/* <span className="text-orange-500">regard to access of information</span> */}
                    </div>
                    <div className="mt-6 w-full text-lg blog_intro max-md:max-w-full">
                        The domain name, website, and app are owned, registered, and operated by
                        "<span className="text-orange-500">Headway Business Solutions LLP</span>,"
                        a limited liability partnership incorpor ated under the (Indian) Companies Act, 2008. Our registered office is located at {officeAddress()}
                    </div>
                </div>

                <div className="mt-8 flex flex-col self-stretch">
                    <div className="w-full text-3xl font-bold max-md:max-w-full  text-orange-500">
                        Service
                    </div>
                    <div className="mt-6 w-full text-lg max-md:max-w-full blog_intro">
                        <span className="text-orange-500">Headway Business Solutions </span> provides jewellery business coaching and consulting services through its Platform.
                        We offer educational resources, training materials, and expert guidance to individuals and businesses in the jewellery industry.
                    </div>
                    <div className="mt-6 w-full text-lg max-md:max-w-full blog_intro">
                        The Website and/or App is an online platform that supports and facilitates the online creation of educational videos/or tutorials,
                        by the Users of the Website and/or App as an intermediary between the educator and the user (‘Service”).
                        A User may create a video, audio clip, or tutorial, by using our Services. Such content uploaded through the
                        use of our Services or otherwise submitted to us for uploading on our Website and/or App shall hereinafter be referred to as
                        “User Content”.
                    </div>
                    <div className="mt-6 w-full text-lg max-md:max-w-full blog_intro">
                        You agree and acknowledge that&nbsp;
                        <span className="text-orange-500">Headway Business Solutions LLP</span>
                        &nbsp;has no control over and assumes no responsibility for the User Content and by using the Service,
                        you expressly relieve
                        Headway Business Solutions LLP
                        from any and all liability arising from the User Content.
                    </div>
                    <div className="mt-6 w-full text-lg max-md:max-w-full blog_intro">
                        The Service may include or contain links to any third-party websites that may or may not be owned or controlled by&nbsp;
                        <span className="text-orange-500">Headway Business Solutions LLP</span>
                        .&nbsp;
                        <span className="text-orange-500">Headway Business Solutions LLP</span>
                        &nbsp;has no control over and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites.
                    </div>
                    <div className="mt-6 w-full text-lg max-md:max-w-full blog_intro">
                        In addition,&nbsp;
                        <span className="text-orange-500">Headway Business Solutions LLP</span>
                        &nbsp;will not and cannot censor or edit the content of any third-party site. By using the Service, you expressly relieve&nbsp;
                        <span className="text-orange-500">Headway Business Solutions LLP</span>
                        &nbsp;from any and all liability emerging from your use of any third-party website or services. However, we recommend that before you use any third-party service or website you read and understand the terms and conditions and privacy policy of each website that you visit.
                    </div>
                    <div className="mt-6 w-full text-lg max-md:max-w-full blog_intro">
                        Subject to these Terms of Service and the Privacy Policy,&nbsp;
                        <span className="text-orange-500">Headway Business Solutions LLP</span>
                        &nbsp;hereby grants the user a limited, non-transferable, non-exclusive, limited privilege to use this Website and/or App.
                    </div>
                    <div className="mt-6 w-full text-lg max-md:max-w-full blog_intro">
                        You agree and acknowledge that&nbsp;
                        <span className="text-orange-500">Headway Business Solutions LLP</span>
                        &nbsp;shall have the right at any time to change or discontinue any aspect or feature of the Website and/or App,
                        including, but not limited to, the User Content, hours of availability, and equipment needed for access or use. Further,&nbsp;
                        <span className="text-orange-500">Headway Business Solutions LLP</span>
                        &nbsp;may terminate publishing any portion of information or category of information may change or eliminate any transmission method, and may change transmission speeds or other signal characteristics.
                    </div>
                    <div className="mt-6 w-full text-lg max-md:max-w-full blog_intro">
                        <span className="text-orange-500">Headway Business Solutions LLP</span>
                        &nbsp;reserves the right to refuse access to the Website and/or App, terminate Accounts, or remove or edit contents without any notice to You.
                    </div>
                </div>

                {/* <div className="mt-8 flex flex-col self-stretch blog_intro">
                    <div className="w-full text-3xl font-medium text-orange-500 max-md:max-w-full">
                        Service
                    </div>
                    <div className="mt-6 w-full max-md:max-w-full">
                        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                            <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                                <div className="grow self-stretch px-4 pt-4 pb-16 w-full text-lg  rounded-2xl terms_bg_clr max-md:mt-6 max-md:max-w-full">
                                    1. These Terms of Service apply to all Users of the Service,
                                    including users, educators who are also contributors of User
                                    Content on the Service. The Service includes all aspects of{" "}
                                    <span className="text-orange-500">
                                        Headway Business Solutions LLP
                                    </span>{" "}
                                    website which includes but is not limited to products, software
                                    and services offered via the{" "}
                                    <span className="text-orange-500">
                                        Headway Business Solutions LLP
                                    </span>{" "}
                                    website and/or App, such as the{" "}
                                    <span className="text-orange-500">
                                        Headway Business Solutions LLP
                                    </span>{" "}
                                    online platform,{" "}
                                    <span className="text-orange-500">
                                        Headway Business Solutions LLP Plus
                                    </span>
                                    ,{" "}
                                    <span className="text-orange-500">
                                        Headway Business Solutions LLP
                                    </span>{" "}
                                    Bytes and any other service or application that{" "}
                                    <span className="text-orange-500">
                                        Headway Business Solutions LLP
                                    </span>{" "}
                                    introduces from time to time.
                                </div>
                            </div>
                            <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
                                <div className="grow self-stretch px-4 pt-4 pb-10 w-full text-lg rounded-2xl terms_bg_clr max-md:mt-6 max-md:max-w-full">
                                    2. The Website and/or App is an online platform that supports and
                                    facilitates the online creation of educational videos/or
                                    tutorials, by the Users of the Website and/or App as an
                                    intermediary between the educator and the user (‘Service”). A User
                                    may create a video, audio clip or tutorial, by using our Services.
                                    Such content uploaded through the use of our Services or otherwise
                                    submitted to us for uploading on our Website and/or App shall
                                    hereinafter be referred to as “User Content”. You agree and
                                    acknowledge that{" "}
                                    <span className="text-orange-500">
                                        Headway Business Solutions LLP
                                    </span>{" "}
                                    has no control over and assumes no responsibility for, the User
                                    Content and by using the Service, you expressly relieve{" "}
                                    <span className="text-orange-500">
                                        Headway Business Solutions LLP
                                    </span>{" "}
                                    from any and all liability arising from the User Content.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-6 w-full max-md:max-w-full">
                        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                            <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                                <div className="grow justify-center self-stretch p-4 w-full text-lg rounded-2xl terms_bg_clr max-md:mt-6 max-md:max-w-full">
                                    3. The Service may include or contain links to any third party
                                    websites that may or may not be owned or controlled by{" "}
                                    <span className="text-orange-500">
                                        Headway Business Solutions LLP
                                    </span>
                                    .{" "}
                                    <span className="text-orange-500">
                                        Headway Business Solutions LLP
                                    </span>{" "}
                                    has no control over, and assumes no responsibility for, the
                                    content, privacy policies, or practices of any third party
                                    websites. In addition,{" "}
                                    <span className="text-orange-500">
                                        Headway Business Solutions LLP
                                    </span>{" "}
                                    will not and cannot censor or edit the content of any third-party
                                    site. By using the Service, you expressly relieve{" "}
                                    <span className="text-orange-500">
                                        Headway Business Solutions LLP
                                    </span>{" "}
                                    from any and all liability arising from your use of any
                                    third-party website or services. However, we recommend that before
                                    you use any third party service or website you read and understand
                                    the terms and conditions and privacy policy of each such website
                                    that you visit.
                                </div>
                            </div>
                            <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
                                <div className="grow self-stretch px-4 pt-4 pb-10 w-full text-lg  rounded-2xl terms_bg_clr max-md:mt-6 max-md:max-w-full">
                                    5. You agree and acknowledge that Headway Business Solutions LLP
                                    shall have the right at any time to change or discontinue any
                                    aspect or feature of the Website and/or App, including, but not
                                    limited to, the User Content, hours of availability and equipment
                                    needed for access or use. Further,{" "}
                                    <span className="text-orange-500">
                                        Headway Business Solutions LLP
                                    </span>{" "}
                                    may discontinue disseminating any portion of information or
                                    category of information may change or eliminate any transmission
                                    method and may change transmission speeds or other signal
                                    characteristics.{" "}
                                    <span className="text-orange-500">
                                        Headway Business Solutions LLP
                                    </span>{" "}
                                    reserves the right to refuse access to the Website and/or App,
                                    terminate Accounts, remove or edit contents without any notice to
                                    You.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="justify-center p-4 mt-6 w-full text-lg rounded-2xl terms_bg_clr max-md:max-w-full">
                        5. You agree and acknowledge that{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        shall have the right at any time to change or discontinue any aspect or
                        feature of the Website and/or App, including, but not limited to, the
                        User Content, hours of availability and equipment needed for access or
                        use. Further,{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        may discontinue disseminating any portion of information or category of
                        information may change or eliminate any transmission method and may
                        change transmission speeds or other signal characteristics.{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        reserves the right to refuse access to the Website and/or App, terminate
                        Accounts, remove or edit contents without any notice to You.
                    </div>
                </div> */}

                <div className="mt-8 flex flex-col self-stretch">
                    <div className="w-full text-[32px] font-bold max-md:max-w-full  text-zinc-800">
                        Headway Business Solutions LLP <span className="text-orange-500">Accounts</span>
                    </div>
                    <div className="mt-6 w-full text-lg max-md:max-w-full blog_intro">
                        In order to access some of the features of the Service, you may have to create an account with{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>.
                        LLP. You agree and confirm that you will never use another
                        User&#39;s account without permission of the said User. When creating your account, you confirm
                        that the information you provide is accurate and complete. Further, you agree that you are
                        solely responsible for the activities that occur on your account, and you shall keep your account
                        password secure and not share the same with anyone.


                        <div className="mt-6 w-full text-lg max-md:max-w-full blog_intro">
                            You must notify{" "}
                            <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                            immediately of any breach of security or
                            unauthorized use of your account. At no point will{" "}
                            <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                            be liable for
                            your losses caused by any unauthorized use of your account. You shall solely be liable for the
                            losses caused to{" "}
                            <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                            or others due to such unauthorized use, if
                            any.
                        </div>

                        <div className="mt-6 w-full text-lg max-md:max-w-full blog_intro">
                            <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                            takes no responsibility for any User Content, uploaded by
                            Users on the Website and/or App, and further, the User shall be solely responsible for his or her
                            own actions in utilizing such User Content, and availing the Services provided herein.
                        </div>
                    </div>
                </div>


                <div className="flex flex-col self-stretch text-lg blog_intro mt-8">
                    <div className="w-full text-[32px] font-bold max-md:max-w-full text-zinc-800">
                        Access{" "}
                        <span className="text-orange-500">Permissions and Restrictions</span>
                    </div>
                    <div className="mt-6 w-full text-zinc-600 max-md:max-w-full">
                        By accessing the Service, you agree to these Terms. You are granted a non-exclusive, non-transferable,
                        revocable license to use the Service in accordance with these Terms. You allow&nbsp;
                        <span className="text-orange-500">Headway Business Solutions LLP</span>
                        &nbsp;limited, royalty-free, worldwide, non-exclusive license to use the User Content and communication in developing its Service and in any of its marketing or promotional activities
                    </div>

                    <div className=" mt-6 flex flex-col self-stretch p-6 rounded-xl terms_bg_clr max-md:px-5">
                        <div className="text-2xl font-bold text-zinc-800 max-md:max-w-full">
                            Permissions
                        </div>
                        <div className="mt-6 text-lg text-zinc-600 max-md:max-w-full">
                            {/* 1. You agree not to distribute in any medium any part of the Service or
                            the Content without{" "} */}
                             {/* <span className="text-orange-500">Headway Business Solutions LLP</span>
                            ’s prior written authorization. */}
                            1. You are permitted to access and use the Content for your personal use only.
                           
                            <br />​<br />
                            2. You may download and print copies of certain Content for your
                            personal use, provided you do not remove any copyright or trademark
                            notices.
                            <br />​<br />
                            3. You may post reviews, comments, and other content; send other
                            communications; and submit suggestions, ideas, comments, questions, or
                            other information as long as the content is not illegal, obscene,
                            threatening, defamatory, invasive of privacy, infringement of
                            intellectual property rights, or otherwise injurious to third parties or
                            objectionable and does not consist of or contain software viruses,
                            political campaigning, commercial solicitation, chain letters, mass
                            mailings or any other form of spam.
                        </div>
                    </div>

                    <div className="mt-6 flex flex-col self-stretch p-6 rounded-xl terms_bg_clr max-md:px-5">
                        <div className="text-2xl font-bold text-zinc-800 max-md:max-w-full">
                            Restrictions
                        </div>
                        <div className="mt-6 text-lg text-zinc-600 max-md:max-w-full">
                            1. You may not share your account access with any third party.
                            <br />​<br />
                            2. You may not modify, reverse engineer, decompile, disassemble, or
                            attempt to discover the source code of the Service.
                            <br />​<br />
                            3. You may not use the Service for any illegal or unauthorized
                            purpose.
                            <br />​<br />
                            4. You may not violate any applicable laws or regulations.
                        </div>
                    </div>

                    {/* <div className="mt-6 w-full max-md:max-w-full">
                        1. You agree not to distribute in any medium any part of the Service or
                        the Content without{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>
                        ’s prior written authorization.
                        <br />​<br />
                        2. You agree not to alter or modify any part of the Service.
                        <br />​<br />
                        3. You agree not to access Content of any other user through any
                        technology or means other than the video playback pages of the Service
                        itself.
                        <br />​<br />
                        4. You agree not to use the Service for any of the following commercial
                        uses unless you obtain{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>
                        ’s prior written approval:
                        <br />​<br />
                        the sale of access to the Service; the sale of advertising,
                        sponsorships, or promotions placed on or within the Service or Content;
                        or the sale of advertising, sponsorships, or promotions on any page or
                        website that provide similar service as that of{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>.
                        <br />​<br />
                        5. You agree to installs and updates from time to time from{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>.
                        These updates are designed to improve, enhance and further develop the
                        Service and may take the form of bug fixes, enhanced functions, new
                        software modules and completely new versions. You agree to receive such
                        updates (and permit{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        to deliver these to you) as part of your use of the Service.
                        <br />​<br />
                        6. You agree not to use or launch any automated system, including
                        without limitation, “robots,” “spiders,” or “offline readers,” that
                        accesses the Service in a manner that sends more request messages to the{" "}
                        <span className="text-orange-500">
                            Headway Business Solutions LLP
                        </span>{" "}
                        servers in a given period of time than a human can reasonably produce in
                        the same period by using a conventional on-line web browser.
                        Notwithstanding the foregoing,{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        grants the operators of public search engines permission to use spiders
                        to copy materials from the site for the sole purpose of and solely to
                        the extent necessary for creating publicly available searchable indices
                        of the materials, but not caches or archives of such materials.{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        reserves the right to revoke these exceptions either generally or in
                        specific cases at any time with or without providing any notice in this
                        regard. You agree not to collect or harvest any personally identifiable
                        information, including account names, from the Service, nor to use the
                        communication systems provided by the Service (e.g., comments, email)
                        for any commercial solicitation purposes. You agree not to solicit, for
                        commercial purposes, any Users of the Service with respect to User
                        Content.
                        <br />​<br />
                        7. You may post reviews, comments and other content; send other
                        communications; and submit suggestions, ideas, comments, questions or
                        other information as long as the content is not illegal, obscene,
                        threatening, defamatory, invasive of privacy, infringement of
                        intellectual property rights, or otherwise injurious to third parties or
                        objectionable and does not consist of or contain software viruses,
                        political campaigning, commercial solicitation, chain letters, mass
                        mailings or any other form of spam. Further, you give{" "}
                        <span className="text-orange-500">
                            Headway Business Solutions LLP
                        </span>{" "}
                        limited, royalty free, worldwide, non-exclusive license to use the User
                        Content and communication in developing its Service and in any of its
                        marketing or promotional activities.
                        <br />​<br />
                        8. In your use of the Service, you will at all times comply with all
                        applicable laws and regulations.
                        <br />​<br />
                        9.{" "}
                        <span className="text-orange-500">
                            Headway Business Solutions LLP
                        </span>{" "}
                        reserves the right to discontinue any aspect of the Service at any time
                        with or without notice at its sole discretion.
                    </div> */}
                </div>





                {/* <div className="flex flex-col self-stretch text-lg blog_intro mt-8">
                    <div className="w-full text-3xl font-medium max-md:max-w-full" style={{ color: '#313131' }}>
                                Content <span className="text-orange-500">Use</span>
                    </div>
                    <div className="mt-6 w-full text-zinc-600 max-md:max-w-full">
                        In addition to the general restrictions mentioned above, the following
                        limitation and conditions shall apply to your use of the Content.
                    </div>
                    <div className="mt-6 w-full max-md:max-w-full">
                        1.{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        Content utilized in the Service which shall include but not be limited
                        to trademarks, service marks and logos (“Marks”), process, images,
                        software, graphics on the Service, are owned by or licensed to{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        and subject to copyright and other intellectual property rights under
                        the law.
                        <br />​<br />
                        2. User Content is provided to you on an AS IS basis. You may access
                        Content for your information and personal use solely as intended through
                        the provided functionality of the Service and as permitted under these
                        Terms of Service. You shall not download any User Content unless you see
                        a “download” or similar link displayed by{" "}
                        <span className="text-orange-500">
                            Headway Business Solutions LLP
                        </span>{" "}
                        on the Service for that User Content. You shall not copy, reproduce,
                        make available online or electronically transmit, publish, adapt,
                        distribute, transmit, broadcast, display, sell, license, or otherwise
                        exploit any User Content for any other purposes other than as provided
                        herein without the prior written consent of{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        or the respective licensors of the User Content.{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        and its licensors reserve all rights not expressly granted in and to the
                        Service and the Content.
                        <br />​<br />
                        3. You agree not to circumvent, disable or otherwise interfere with
                        security-related features of the Service or features that prevent or
                        restrict use or copying of any Content or enforce limitations on use of
                        the Service or the Content therein.
                        <br />​<br />
                        4. You understand that when using the Service, you will be exposed to
                        User Content from a variety of sources, and that{" "}
                        <span className="text-orange-500">
                            Headway Business Solutions LLP
                        </span>{" "}
                        is not responsible for the accuracy, usefulness, safety, or intellectual
                        property rights of or relating to such User Content. You further
                        understand and acknowledge that you may be exposed to User Content that
                        is inaccurate, offensive, indecent, or objectionable, and you agree to
                        waive, and hereby do waive, any legal or equitable rights or remedies
                        you have or may have against{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        with respect thereto, and, to the extent permitted by applicable law,
                        agree to indemnify and hold harmless{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>,
                        its owners, operators, affiliates, licensors, and licensees to the
                        fullest extent allowed by law regarding all matters related to your use
                        of the Service.
                    </div>
                </div>


                <div className="mt-8 flex flex-col self-stretch blog_intro">
                    <div className="w-full text-3xl font-medium text-zinc-800 max-md:max-w-full">
                        Content and <span className="text-orange-500">Conduct</span>
                    </div>
                    <div className="mt-6 w-full text-lg  max-md:max-w-full">
                        1. As a{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        user you may submit User Content on the Service, including videos and
                        user comments. You understand that{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        does not guarantee any confidentiality with respect to any User Content
                        you submit.
                        <br />​<br />
                        2. You shall be solely responsible for your own User Content and the
                        consequences of submitting and publishing such User Content on the
                        Service. You affirm, represent, and warrant that you own or have the
                        necessary licenses, rights, consents, and permissions to publish the
                        User Content that you submit; and you license to{" "}
                        <span className="text-orange-500">
                            Headway Business Solutions LLP
                        </span>{" "}
                        all patent, trademark, trade secret, copyright or other proprietary
                        rights in and to such User Content for publication on the Service
                        pursuant to these Terms of Service for the duration the said User
                        Content is available on{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>
                        ’s Service platform.
                        <br />​<br />
                        3. For clarity, you retain your ownership rights in your User Content.
                        However, by submitting User Content to{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>,
                        you hereby grant{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        a worldwide, non-exclusive, royalty-free, sub-licensable and
                        transferable license to use, reproduce, distribute, prepare derivative
                        works of, display, publish, adapt, make available online or
                        electronically transmit, and perform the User Content in connection with
                        the Service and{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>
                        ’s (and its successors’ and affiliates’) business, including without
                        limitation for promoting and redistributing part or all of the Service
                        (and derivative works thereof) in any media formats and through any
                        media channels. You also hereby grant each user of the Service a
                        non-exclusive license to access your User Content through the Service.
                        The above licenses granted by you in video User Content you submit to
                        the Service terminate within a commercially reasonable time after you
                        request{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        to remove or delete your videos from the Service provided you pay the
                        mutually agreed amount that{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        incurred in providing the Service platform to you. You understand and
                        agree, however, that{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        may retain, but not display, distribute, or perform, server copies of
                        your videos that have been removed or deleted. The above licenses
                        granted by you in User comments and testimonial you submit are perpetual
                        and irrevocable.
                        <br />​<br />
                        4. You further agree that User Content you submit to the Service will
                        not contain third party copyrighted material, or material that is
                        subject to other third party proprietary rights, unless you have
                        permission from the rightful owner of the material or you are otherwise
                        legally entitled to post the material and to grant{" "}
                        <span className="text-orange-500">
                            Headway Business Solutions LLP
                        </span>{" "}
                        all of the license rights granted herein.
                    </div>
                    <div className="flex flex-col p-4 mt-6 w-full rounded-2xl terms_bg_clr max-md:max-w-full">
                        <div className="text-xl font-medium text-orange-500 max-md:max-w-full">
                            5. Furthermore, you confirm that the Content uploaded by you is not:
                        </div>
                        <div className="mt-2.5 text-lg text-zinc-600 max-md:max-w-full">
                            1. False, inaccurate or misleading;
                            <br />
                            2. Infringe any third party’s copyright, patent, trademark, trade
                            secret or other proprietary rights or rights of publicity or privacy;
                            <br />
                            3. Violate any law, statue, ordinance or regulation;
                            <br />
                            4. defamatory, unlawfully threatening or unlawfully harassing;
                            <br />
                            5. obscene or contain pornography.
                            <br />
                            6. Contain any viruses, trojan horses, worms, cancelbots or other
                            computer programming routines that may damage, detrimentally interfere
                            with, surreptitiously intercept or expropriate any system, data or
                            personal information.
                        </div>
                    </div>
                    <div className="mt-6 w-full text-lg max-md:max-w-full">
                        6. You understand and confirm that you shall not during your use of the
                        Services at any time post or publish any content, comments or act in any
                        way which will amount to harassment of any other User, whether a learner
                        or an educator. If at any given point it comes to the notice of{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        that you are engaged in any kind of harassment of other Users, then in
                        such a case you agree that{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        shall have the sole right to suspend/terminate your account with
                        immediate effect and without any notice of such suspension or
                        termination and we also reserve the right in our sole discretion to
                        initiate any legal proceedings against you in such cases.
                        <br />​<br />
                        7.{" "}
                        <span className="text-orange-500">
                            Headway Business Solutions LLP
                        </span>{" "}
                        at its sole discretion may process any audio or audio-visual content
                        uploaded by you to the Service to confirm if the same is in accordance
                        with the{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        internal quality requirements.
                        <br />​<br />
                        8.{" "}
                        <span className="text-orange-500">
                            Headway Business Solutions LLP
                        </span>{" "}
                        does not endorse any User Content submitted to the Service by any User
                        or other licensor, or any opinion, recommendation, or advice expressed
                        therein, and{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        expressly disclaims any and all liability in connection with User
                        Content.{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        does not permit copyright infringing activities and infringement of
                        intellectual property rights on the Service, and{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        will remove all User Content if properly notified that such Content
                        infringes on another’s intellectual property rights.{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        reserves the right to remove User Content without prior notice if it has
                        reason to believe that the User Content infringes any copyright.
                    </div>
                </div>


                <div className="mt-8 flex flex-col self-stretch blog_intro">
                    <div className="w-full text-3xl font-medium max-md:max-w-full text-zinc-800">
                        Paid Course <span className="text-orange-500">Condition</span>
                    </div>
                    <div className="mt-6 w-full text-lg max-md:max-w-full">
                        Further, the courses made available on{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        can be streamed live on the Website for a period of 2 years only,
                        following which the video will be automatically deleted and access to
                        the same shall be permanently shut down. To access the course/lessons
                        the user should have:
                        <br />​<br />
                        1. a minimum of 3 Mbps speed internet connection for optimal usage of{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>.
                        <br />​<br />
                        2. The course purchased on{" "}
                        <span className="text-orange-500">
                            Headway Business Solutions LLP
                        </span>{" "}
                        is exclusive for the users enrolled and who have paid and subscribed to
                        it. You shall not give any unauthorised access or distribute or
                        reproduce the Content to a third party.
                        <br />​<br />
                        In the event that{" "}
                        <span className="text-orange-500">
                            Headway Business Solutions LLP
                        </span>{" "}
                        becomes aware of any users giving unauthorised access to any third
                        party,{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        reserves the right to suspend or terminate the said User’s account.{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        Intellectual Property Rights
                        <br />​<br />
                        The Service, the processes, and their selection and arrangement,
                        including but not limited to all text, graphics, user interfaces, visual
                        interfaces, sounds and music (if any), artwork and computer code on the
                        Website and/or App is owned and controlled by{" "}
                        <span className="text-orange-500">
                            Headway Business Solutions LLP
                        </span>{" "}
                        and the design, structure, selection, coordination, expression, look and
                        feel and arrangement of such content mentioned herein-above is protected
                        by copyright, patent and trademark laws, and various other national and
                        international IPR laws and regulations. For clarity, content in the
                        above sentence shall not include User Content.
                        <br />​<br />
                        Unless otherwise indicated or anything contained to the contrary, or any
                        proprietary material owned by a third-party and so expressly mentioned,{" "}
                        <span className="text-orange-500">
                            Headway Business Solutions LLP
                        </span>{" "}
                        owns all IPR to and into the trademark “
                        <span className="text-orange-500">Headway Business Solutions LLP</span>
                        ”, and the Website and/or App.
                        <br />​<br />
                        The mark “{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>”
                        is the sole property of{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>.
                        Reproduction in whole or in part of the same is strictly prohibited
                        unless used with an express written permission from{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>.
                    </div>
                </div>


                <div className="flex flex-col self-stretch blog_intro mt-8">
                    <div className="w-full text-3xl font-medium max-md:max-w-full  text-zinc-800">
                        Payment <span className="text-orange-500">Terms</span>
                    </div>
                    <div className="mt-6 w-full text-lg max-md:max-w-full">
                        If you as a User has uploaded any content as an educator, then in
                        consideration for the Content uploaded by you,{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        shall make payment, if eligible, for the same based on the payment
                        structure communicated to you from time to time.
                        <br />​<br />
                        If you as a User has registered for a course, the you have to make all
                        the required payments for the said course within the timelines mentioned
                        on the page of the respective course.
                        <br />​<br />
                        Payments shall be made within the timelines that are communicated to
                        you. All payments made herein, shall be subject to applicable taxes and
                        non refundable.
                    </div>
                </div>


                <div className="flex flex-col self-stretch blog_intro mt-8">
                    <div className="w-full text-3xl font-medium max-md:max-w-full  text-zinc-800">
                        Electronic <span className="text-orange-500">Communication</span>
                    </div>
                    <div className="mt-6 w-full text-lg max-md:max-w-full">
                        When you visit{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        or send email to us, you are communicating with us electronically. By
                        communicating with us, you consent to receive communication from us
                        electronically. We will communicate with you by email or posting notices
                        on this site. You agree that all agreements, notices, disclosures,
                        disclaimers, offers and other communications that are provided to you
                        electronically satisfy any legal requirement that such communication
                        should be in writing.
                    </div>
                </div>


                <div className="flex flex-col self-stretch  blog_intro mt-8">
                    <div className="w-full text-3xl font-medium max-md:max-w-full text-zinc-800">
                        Termination of <span className="text-orange-500">Account</span>
                    </div>
                    <div className="flex flex-col p-4 mt-6 w-full rounded-2xl terms_bg_clr max-md:max-w-full">
                        <div className="text-xl font-medium max-md:max-w-full text-orange-500">
                            1. Headway Business Solutions LLP will terminate a user's access to
                            the Service, if
                        </div>
                        <div className="mt-2.5 text-lg max-md:max-w-full">
                            1. The user is a repeat copyright infringer;
                            <br />
                            2. The users breaches any terms of these Terms of Service.
                            <br />
                            3. Violation of any applicable laws;
                            <br />
                            4. Your use of the Service disrupts our business operations or affects
                            any other party/ User; or
                            <br />
                            5. You have behaved in a way, which objectively could be regarded as
                            inappropriate or unlawful or illegal or which would bring any claims
                            against{" "}
                            <span className="text-orange-500">
                                Headway Business Solutions LLP
                            </span>
                            .
                        </div>
                    </div>
                    <div className="justify-center p-4 mt-6 w-full text-lg rounded-2xl terms_bg_clr max-md:max-w-full">
                        2.{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        reserves the right to decide whether Content violates these Terms of
                        Service for reasons other than copyright infringement, such as, but not
                        limited to, pornography, obscenity, or excessive length or any other
                        parameter that{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        deems fit from time to time.{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        may at any time, without prior notice and in its sole discretion, remove
                        such Content and/or terminate a user's account for submitting such
                        material in violation of these Terms of Service.
                    </div>
                    <div className="justify-center p-4 mt-6 w-full text-lg rounded-2xl terms_bg_clr text-zinc-600 max-md:max-w-full">
                        3. We may suspend access to the Services or require You to change Your
                        password if we reasonably believe that the Services have been or are
                        likely to be misused, and we will notify You accordingly. Any
                        termination of Your registration and/or Account or the Services will not
                        affect liability previously incurred by You.
                    </div>
                </div>


                <div className="flex flex-col self-stretch blog_intro mt-8">
                    <div className="w-full text-3xl font-medium text-zinc-800 max-md:max-w-full">
                        Effective of <span className="text-orange-500">Termination</span>
                    </div>
                    <div className="mt-6 w-full text-lg  max-md:max-w-full">
                        If a user’s account is terminated for any of the above-mentioned reason
                        in such cases,{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        reserves the right, in its sole discretion, to terminate or suspend the
                        user’s account with immediate effect. Upon suspension or termination of
                        the User’s account:
                        <br />​<br />
                        1.{" "}
                        <span className="text-orange-500">
                            Headway Business Solutions LLP
                        </span>{" "}
                        shall at its sole discretion withhold or stop any and all payments that
                        are to be made to such user and evaluate the pay-outs. Payments shall
                        only be made for all legitimate work that is not subject matter of any
                        violation or dispute.
                        <br />​<br />
                        2. Payments shall not be made for the work that is the subject matter of
                        any of the breaches.
                        <br />​<br />
                        3. User access shall be terminated immediately and the user shall not be
                        able to access the said terminated account. Copyright Infringement
                    </div>
                    <div className="justify-center p-4 mt-6 w-full text-lg rounded-2xl terms_bg_clr max-md:max-w-full">
                        1.{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        operates a clear copyright policy in relation to any Content alleged to
                        infringe the copyright of a third party.
                        <br />​<br />
                        2. As part of{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>
                        's copyright policy,{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        will terminate user access to the Website if a user has been determined
                        to be a repeat infringer. A repeat infringer is a user who has been
                        notified of infringing activity more than twice.
                        <br />​<br />3 If you believe that your work has been copied in a way
                        that constitutes copyright infringement, please refer to the
                        instructions given in the copyright policy on how to make a claim of
                        copyright infringement.
                    </div>
                    <div className="flex flex-col p-4 mt-6 w-full rounded-2xl terms_bg_clr max-md:max-w-full">
                        <div className="text-xl font-medium text-orange-500 max-md:max-w-full">
                            4. Notice of Copyright Infringement:
                        </div>
                        <div className="mt-2.5 text-lg text-zinc-600 max-md:max-w-full">
                            1. Pursuant to provisions of the Indian Copyright Act, 1952 read with
                            the Information Technology Act of 2000, we are an intermediary
                            platform that allows general public to post content on its online
                            platform. We have implemented procedures for receiving written
                            notification of claimed infringements on the content uploaded on our
                            online platform. We have also designated an agent to receive notices
                            of claimed copyright infringement. If you believe in good faith that
                            your copyright has been infringed, you may provide us with a written
                            communication which contains:
                            <br />​<br />
                            1. An electronic or physical signature of the person authorised to act
                            on behalf of the owner of the copyright interest;
                            <br />​<br />
                            2. A description of the copyrighted work that you claim has been
                            infringed upon;
                            <br />​<br />
                            3. A description of where the material that you claim is infringing is
                            located on the website, including the educator name, topic and any
                            other detail identifying the infringing content;
                            <br />​<br />
                            4. Your email address, your physical address and telephone number;
                            <br />​<br />
                            5. A statement by you that you have a good faith belief that the
                            disputed use is not authorised by the copyright owner, its agent, or
                            the law; and.
                            <br />​<br />
                            6. A statement by you, made under penalty of perjury, that the
                            information in your notice is accurate and that you are the copyright
                            owner or authorised to act on the copyright owner’s behalf.
                        </div>
                    </div>
                </div>


                <div className="flex flex-col self-stretch blog_intro mt-8 rounded-2xl">
                    <div className="w-full text-xl font-medium max-md:max-w-full  text-orange-500">
                        Please submit your notice to our Copyright Agent as follows:
                    </div>
                    <div className="mt-2.5 w-full text-lg max-md:max-w-full">
                        Email us at &nbsp;
                        <span className="font-medium text-orange-500">
                            Info@headway.org.in{" "}
                        </span>
                        <br />
                        Or contact us by mail at: <br />
                        Headway Business Solutions LLP
                        <br />
                        305, Supermall, C G Road, Near Lal Bunglow, Navrangpura,
                        AhmedabadGujarat, IndiaPin: 380009
                    </div>
                </div>

                <div className="flex flex-col self-stretch rounded-2xl  blog_intro mt-8">
                    <div className="w-full text-xl font-medium max-md:max-w-full text-orange-500">
                        1. Our policy to deal with Copyright infringements:
                    </div>
                    <div className="mt-2.5 w-full text-lg max-md:max-w-full">
                        We follow a 3 strike policy- Three instances brought to our notice will
                        lead to the educator getting banned from our platform. His/her right to
                        any and all compensation or monetary benefit that he/she might be
                        eligible for shall be revoked.
                        <br />​<br />
                        1. First case of violation- The content is taken down and educator is
                        given a warning and is suspended for a week from making any new courses.
                        <br />​<br />
                        2. Second case of violation- The educator is suspended from making any
                        more videos on the platform for a month and all instances of copyright
                        violations are brought down immediately.
                        <br />​<br />
                        3. Third violation- The educator is banned from the platform and will be
                        unable to make any more courses in the future.
                        <br />
                        Confidentiality
                        <br />​<br />
                        You will not without obtaining prior written consent of{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>,
                        disclose to third party any Confidential Information (as defined below)
                        that is disclosed to you during the term of your use of the Service.
                        <br />
                        For the purpose of this clause Confidential Information shall include
                        but shall not be limited be limited to pricing, payment terms, employee
                        details, user list, business model, processes, ideas, concepts etc.
                        relating to{" "}
                        <span className="text-orange-500">
                            Headway Business Solutions LLP
                        </span>{" "}
                        which are not available in the public domain. You acknowledge and agree
                        that the Confidential Information so provided to you shall at all time
                        be the property of{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        and any breach of the same shall cause irreparable damage to us
                    </div>
                </div>


                <div className="flex flex-col self-stretch mt-8">
                    <div className="w-full text-3xl font-medium text-zinc-800 max-md:max-w-full">
                        Disclaimer
                    </div>
                    <div className="mt-6 w-full text-lg text-zinc-600 max-md:max-w-full">
                        You agree that your use of the services shall be at your sole risk. To
                        the fullest extent permitted by law, headway business solutions llp, its
                        officers, directors, employees, and agents exclude all warranties,
                        express or implied, in connection with the services and your use
                        thereof. To the fullest extent permitted by law, headway business
                        solutions llp excludes all warranties, conditions, terms or
                        representations about the accuracy or completeness of the content or the
                        content of any sites so linked and assumes no liability or
                        responsibility for any (i) errors, mistakes, or inaccuracies of user
                        content or any content on headway business solutions llp, (ii) personal
                        injury or property damage, of any nature whatsoever, resulting from your
                        access to and use of our services, (iii) any unauthorised access to or
                        use of our secure servers and/or any and all personal information and/or
                        financial information stored therein, (iv) any interruption or cessation
                        of transmission to or from our services, (iv) any bugs, viruses, trojan
                        horses, or the like which may be transmitted to or through our services
                        by any third party, and/or (v) any errors or omissions in any user
                        content or any other content or for any loss or damage of any kind
                        incurred as a result of the use of any user content or any other content
                        that is posted, emailed, transmitted, or otherwise made available via
                        the services. Headway business solutions llp does not warrant, endorse,
                        guarantee, or assume responsibility for any product or service
                        advertised or offered by a third party through the services or any
                        hyperlinked services or featured in any banner or other advertising, and
                        headway business solutions llp will not be a party to or in any way be
                        responsible for monitoring any transaction between you and third-party
                        providers of products or services. As with the purchase of a product or
                        service through any medium or in any environment, you should use your
                        best judgment and exercise caution where appropriate.
                    </div>
                </div>


                <div className="flex flex-col self-stretch blog_intro mt-8">
                    <div className="w-full text-3xl font-medium max-md:max-w-full text-zinc-800">
                        Limitation of <span className="text-orange-500">Liability</span>
                    </div>
                    <div className="mt-6 w-full text-lg max-md:max-w-full">
                        To the fullest extent permitted by law, in no event shall headway
                        business solutions llp, its officers, directors, employees, or agents,
                        be liable to you for any direct, indirect, incidental, special,
                        punitive, losses or expenses or consequential damages whatsoever
                        resulting from any <br />
                        <div className='my-6'>
                            (i) errors, mistakes, or inaccuracies of user
                            content or any other content available at headway business solutions
                            llp
                            <br />
                            (ii) personal injury or property damage, of any nature whatsoever,
                            resulting from your access to and use of our services <br />
                            (iii) any
                            unauthorised access to or use of our secure servers and/or any and all
                            personal information and/or financial information stored therein <br />
                            (iv)
                            any interruption or cessation of transmission to or from our
                            services <br />
                            (v) any bugs, viruses, trojan horses, or the like, which may be
                            transmitted to or through our services by any third party, and/or <br />
                            (vi)
                            any errors or omissions in any user content or any other content or for
                            any loss or damage of any kind incurred as a result of your use of any
                            content posted, emailed, transmitted, or otherwise made available via
                            the services, whether based on warranty, contract, tort, or any other
                            legal theory, and whether or not the company is advised of the
                            possibility of such damages.
                            <br />
                        </div>
                        <div className='mb-6'>
                            We understand that, in some jurisdictions, warranties, disclaimers and conditions may apply that cannot be legally excluded, if that is true in your jurisdiction, then to the extent permitted by law, headway business solutions llp limits its liability for any claims under those warranties or conditions to either supplying you the services again (or the cost of supplying you the services again).
                            You specifically acknowledge that headway business solutions llp shall not be liable for user content or the defamatory, offensive, or illegal conduct of any third party and that the risk of harm or damage from the foregoing rests entirely with you.
                            The service is controlled and offered by headway business solutions llp from its facilities in India. Headway business solutions llp makes no representations that the service is appropriate or available for use in other locations. Those who access or use the service from other jurisdictions do so at their own volition and are responsible for compliance with local law. <br />
                        </div>
                        <span className="text-2xl font-medium leading-7 text-orange-500 ">
                            Indemnity
                        </span>
                        <br />
                        <br />
                        To the extent permitted by applicable law, you agree to defend, indemnify and hold harmless headway business solutions
                        llp, its parent corporation, officers, directors, employees and agents, from and against any and all claims, damages,
                        obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees) arising from:
                        (i) your use of and access to the service; (ii) your violation of any term of these terms of service; (iii) your violation of
                        any third party right, including without limitation any copyright, property, or privacy right; (iv) any claim that your
                        user content caused damage to a third party; or (v) violation of any applicable laws. This defense and indemnification
                        obligation will survive these terms of service and your use of the service.
                    </div>
                </div>

                <div className="flex flex-col self-stretch text-orange-500  blog_intro mt-8">
                    <div className="w-full text-3xl font-medium max-md:max-w-full text-zinc-800">
                        Eligibility to use and{" "}
                        <span className="text-orange-500">
                            Acceptance of the Terms of Service
                        </span>
                    </div>
                    <div className="mt-6 w-full text-lg max-md:max-w-full">
                        You affirm that you are either more than 18 years of age, or possess
                        legal parental or guardian consent, and are fully able and competent to
                        enter into the terms, conditions, obligations, affirmations,
                        representations, and warranties set forth in these Terms of Service, and
                        to abide by and comply with these Terms of Service. If you are under 18
                        years of age, then please talk to your parents before using this
                        website.
                        <br />​<br />
                        <span className="text-orange-500">
                            Headway Business Solutions LLP
                        </span>{" "}
                        reserves the right to refuse access to use the Services offered at the
                        Website and/or App to any Users or to suspend and/or terminate access
                        granted to existing registered Users at any time without according any
                        reasons for doing so.
                        <br />​<br />
                        We provide these Terms of Service with our Service so that you know what
                        terms apply to your use. You acknowledge that we have given you a
                        reasonable opportunity to review these Terms of Service and that you
                        have agreed to them. You agree and acknowledge that your use of the
                        Website and/or App and the Services is subject to the most current
                        version of the Terms of Service made available on the Website and/or App
                        at the time of such use.
                        <br />
                        Force Majeure
                        <br />​<br />
                        <span className="text-orange-500">
                            Headway Business Solutions LLP
                        </span>{" "}
                        shall not be liable for failure to perform, or the Ahmedabad in
                        performance of, any of its obligations if, and to the extent that, such
                        failure or delay is caused by events substantially beyond the its
                        control, including but not limited to acts of God, acts of the public
                        enemy or governmental body in its sovereign or contractual capacity,
                        war, terrorism, floods, fire, strikes, epidemics, civil unrest or riots,
                        power outage, and/or unusually severe weather.
                        <br />
                        Information Technology Act
                        <br />​<br />
                        Certain laws in India prohibit and impose restriction on use of the
                        Website and you are advised to make familiar with those laws and not to
                        post any information or messages that are, or that may be construed, as
                        being malicious , defamatory, inappropriate, slanderous, pornographic or
                        otherwise sexually oriented or that makes attacks on or the otherwise
                        opines or comments on any individuals or groups of individuals,
                        educational institutions or any other entities whatsoever (whether
                        companies, firms, or any other institutions). You also agree not to post
                        any information to which you do not have copyrights or other appropriate
                        permissions to post in public forum. Your failure to comply with these
                        terms may result in removal of your postings without prior notice to
                        User. The IP address of all posts is recorded to aid in enforcing these
                        conditions.
                        <br />
                        <div className='mt-6'>Other Laws</div>
                        <br />
                        Certain laws require to maintain data with respect to the services,
                        Goods and other personal information in a prescribed format and{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP </span>
                        will use all the information to the extent required in compliance with
                        the applicable laws and as may be directed or amended from time to time.
                        Governing Law and Jurisdiction The Terms of Service are governed by and
                        constructed in accordance with the laws of India, without reference to
                        conflict of laws principles and you irrevocably and unconditionally
                        submit to the exclusive jurisdiction of the courts located in Ahmedabad,
                        Gujarat, India.
                    </div>
                </div>



                <div className="flex flex-col self-stretch mt-8">
                    <div className="w-full text-3xl font-medium text-zinc-800 max-md:max-w-full">
                        General
                    </div>
                    <div className="mt-6 w-full text-lg blog_intro max-md:max-w-full">
                        If any part of these Terms of Service is unlawful, void or
                        unenforceable, that part of will be deemed severable and will not affect
                        the validity and enforceability of any remaining provisions. Any notice
                        required to be given in connection with the Services shall be in writing
                        to the registered office of{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>.
                        We do not guarantee continuous, uninterrupted or secure access to our
                        Service, and operation of the Service may be interfered withy by
                        numerous factors outside our control. Headings are for reference purpose
                        only an on no way define, limit, construe or describe the scope or
                        extent of such section. Out failure to act with respect to any breach by
                        you or others does not waive our right to act with respect to subsequent
                        or similar breaches.
                    </div>
                </div> */}

                <div className="flex flex-col self-stretch mt-8">
                    <div className="w-full text-[32px] font-bold text-zinc-800 max-md:max-w-full">
                        Usage <span className="text-orange-500">of Content</span>
                    </div>
                    <div className="mt-6 w-full text-lg text-zinc-600 max-md:max-w-full">
                        All Content on the Service, including text, graphics, logos, images,
                        audio, video, software, and other materials (&quot;Content&quot;) is the
                        property of{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        or its licensors and is protected by copyright, trademark, and other
                        intellectual property laws.
                    </div>
                    <div className="flex flex-col p-6 mt-6 w-full rounded-xl terms_bg_clr max-md:px-5 max-md:max-w-full">
                        <div className="text-2xl font-bold text-zinc-800 max-md:max-w-full">
                            You may not:
                        </div>
                        <div className="mt-6 text-lg text-zinc-600 max-md:max-w-full">
                            1. Copy, reproduce, modify, publish, distribute, transmit, display,
                            sell, license, or otherwise exploit any Content for any commercial
                            purpose without our express written consent.
                            <br />​<br />
                            2. Remove any copyright or trademark notices from the Content.
                            <br />​<br />
                            3. Create derivative works from the Content.
                            <br />​<br />
                            4. Download any User
                            Content unless you see a “download” or similar link displayed by
                            Headway Business Solutions LLP on the Service for that User
                            Content.
                            <br />​<br />
                            5. Circumvent, disable, or otherwise interfere with
                            security-related features of the Service or features that prevent or
                            restrict use or copying of any Content or enforce limitations on use
                            of the Service or the Content therein.
                        </div>
                    </div>
                    <div className="mt-6 w-full text-lg text-zinc-600 max-md:max-w-full">
                        You understand that when using the Service, you will be exposed to User
                        Content from a variety of sources and that{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        is not responsible for the accuracy, usefulness, safety, or intellectual
                        property rights of or relating to such User Content.
                    </div>
                    <div className="mt-6 w-full text-lg text-zinc-600 max-md:max-w-full">
                        By using the Service, you acknowledge that you may encounter User
                        Content that is inaccurate, offensive, indecent, or upsetting. You agree
                        not to hold{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                        responsible for such content. To the fullest extent permitted by law,
                        you also agree to protect and hold harmless{" "}
                        <span className="text-orange-500">Headway Business Solutions LLP</span>,
                        its owners, operators, affiliates, licensors, and licensees from any
                        legal claims arising from your use of the Service.
                    </div>
                </div>

                <div className="flex flex-col self-stretch mt-8">
                    <div className="w-full text-[32px] font-bold text-zinc-800 max-md:max-w-full">
                        Content and <span className="text-orange-500">Conduct</span>
                    </div>
                    <div className="mt-6 w-full text-lg text-zinc-600 max-md:max-w-full">
                        You are responsible for all content you post, share, or transmit through
                        the Service (&quot;User Content&quot;). You agree not to post User
                        Content that is illegal, obscene, defamatory, threatening, harassing,
                        hateful, or racially or ethnically offensive.
                    </div>
                    <div className="mt-6 w-full text-lg text-zinc-600 max-md:max-w-full">
                        You further agree that User Content you submit to the Service will not
                        contain third-party copyrighted content or material that is subject to
                        other third-party proprietary rights unless you have permission from the
                        rightful owner of the material or you are otherwise legally entitled to
                        post the material and to grant Headway Business Solutions LLP all of the
                        license rights granted herein.
                    </div>
                    {/* <div className="mt-6 w-full text-lg text-zinc-600 max-md:max-w-full">
                        You further agree that User Content you submit to the Service will not
                        contain third-party copyrighted content or material that is subject to
                        other third-party proprietary rights unless you have permission from the
                        rightful owner of the material or you are otherwise legally entitled to
                        post the material and to grant Headway Business Solutions LLP all of the
                        license rights granted herein.
                    </div> */}
                    <div className="flex flex-col p-4 mt-6 w-full text-lg rounded-2xl terms_bg_clr text-zinc-600 max-md:max-w-full">
                        <div className="max-md:max-w-full">
                            You retain ownership of the content you post on the Service
                            (&quot;User Content&quot;). By submitting User Content, you grant
                            Headway a non-exclusive (meaning you can license it elsewhere too),
                            royalty-free (meaning you won't be paid for its use) license to use
                            your content in various ways. This includes using it:
                        </div>
                        <div className="mt-2.5 max-md:max-w-full">
                            1. On the Service
                            <br />
                            2. To promote the Service
                            <br />
                            3. In any format or media channel
                        </div>
                        <div className="mt-2.5 max-md:max-w-full">
                            Other users of the Service are also granted a license to access your
                            User Content through the Service.
                        </div>
                    </div>
                    <div className="flex flex-col p-6 mt-6 w-full rounded-xl terms_bg_clr max-md:px-5 max-md:max-w-full">
                        <div className="text-2xl font-bold text-zinc-800 max-md:max-w-full">
                            About Video Content
                        </div>
                        <div className="mt-6 text-lg text-zinc-600 max-md:max-w-full">
                            If you submit video content, the license you grant Headway terminates
                            within a reasonable time after you request its removal, provided you
                            pay any agreed-upon fees for using the platform. Headway Business
                            Solutions may keep backup copies of your removed videos but won't
                            display or distribute them.
                        </div>
                    </div>
                    <div className="flex flex-col p-6 mt-6 rounded-xl terms_bg_clr max-md:px-5 max-md:max-w-full">
                        <div className="text-2xl font-bold text-zinc-800 max-md:max-w-full">
                            About Comments and Testimonials
                        </div>
                        <div className="mt-6 text-lg text-zinc-600 max-md:max-w-full">
                            The license you grant{" "}
                            <span className="text-orange-500">Headway Business Solutions</span>{" "}
                            for comments and testimonials is permanent and can't be revoked.
                        </div>
                    </div>
                </div>


                <div className="mt-8 flex flex-col self-stretch text-lg text-zinc-600">
                    <div className="w-full text-2xl font-bold text-zinc-800 max-md:max-w-full">
                        IMPORTANT <span className="text-orange-500">DISCLAIMER</span>
                    </div>
                    <div className="mt-6 w-full max-md:max-w-full">
                        Headway doesn't vouch for the accuracy of what users post. This includes
                        any opinions, recommendations, or advice shared by users.
                    </div>
                    <div className="mt-6 w-full max-md:max-w-full">
                        Headway takes copyright infringement seriously and will remove content
                        if it violates intellectual property rights. You can report copyright
                        infringement to Headway.
                    </div>
                    <div className="mt-6 w-full max-md:max-w-full">
                        Headway may also remove content if it believes it violates copyright
                        rules, even without a formal notification.
                    </div>
                </div>


                <div className="flex flex-col self-stretch mt-8">
                    <div className="w-full text-3xl font-bold text-zinc-800 max-md:max-w-full">
                        Paid Course <span className="text-orange-500">Conditions</span>
                    </div>
                    <div className="mt-6 w-full text-lg text-zinc-600 max-md:max-w-full">
                        Some of the Content on the Service may be offered through paid courses
                        or programs.
                        <br />​<br />
                        1. Paid Courses may be subject to additional terms and conditions
                        specific to the course.
                        <br />​<br />
                        2. You are responsible for all fees associated with paid courses.
                    </div>
                    <div className="flex flex-col p-6 mt-6 bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                        <div className="text-2xl font-bold text-zinc-800 max-md:max-w-full">
                            Course Access Requirements:
                        </div>
                        <div className="mt-3 text-lg text-zinc-600 max-md:max-w-full">
                            For an optimal learning experience, we recommend a minimum internet
                            connection speed of 3 Mbps.
                        </div>
                    </div>
                    <div className="flex flex-col p-6 mt-4 text-lg bg-white rounded-xl border border-gray-200 border-solid text-zinc-600 max-md:px-5 max-md:max-w-full">
                        <div className="text-2xl font-bold text-zinc-800 max-md:max-w-full">
                            Course Ownership and Sharing:
                        </div>
                        <div className="mt-3 text-zinc-600 max-md:max-w-full">
                            Courses purchased on{" "}
                            <span className="text-orange-500">
                                Headway Business Solutions LLP
                            </span>{" "}
                            are for the exclusive use of the enrolled user who has paid and
                            subscribed.
                        </div>
                        <div className="mt-3 max-md:max-w-full">
                            Sharing course content (including unauthorized access, distribution,
                            or reproduction) with a third party is strictly prohibited.
                        </div>
                        <div className="mt-3 max-md:max-w-full">
                            <span className="text-orange-500">
                                Headway Business Solutions LLP
                            </span>{" "}
                            reserves the right to suspend or terminate accounts found to be
                            violating these terms.
                        </div>
                    </div>
                    <div className="flex flex-col p-6 mt-4 w-full text-lg text-zinc-600 bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                        <div className="text-2xl font-bold text-zinc-800 max-md:max-w-full">
                            Intellectual Property Rights:
                        </div>
                        <div className="mt-3 max-md:max-w-full">
                            <span className="text-orange-500">
                                Headway Business Solutions LLP
                            </span>{" "}
                            owns and controls all intellectual property rights (IPR) associated
                            with the Service, its processes, and its design (including text,
                            graphics, videos, audio, and interfaces). This protection extends to
                            the website and app design, structure, content selection, and
                            arrangement. (
                            <span className="font-medium text-orange-500">
                                Note: User Content is not included in this ownership.
                            </span>
                            )
                        </div>
                        <div className="mt-3 text-zinc-600 max-md:max-w-full">
                            <span className="text-orange-500">
                                Headway Business Solutions LLP
                            </span>{" "}
                            holds all intellectual property rights to the trademark &quot;
                            <span className="text-orange-500">
                                Headway Business Solutions LLP
                            </span>
                            &quot; and the website/app itself, unless explicitly stated otherwise
                            or owned by a third party.
                        </div>
                        <div className="mt-3 max-md:max-w-full">
                            Using the trademark &quot;
                            <span className="text-orange-500">
                                Headway Business Solutions LLP
                            </span>
                            &quot; in whole or part is strictly prohibited without our express
                            written permission.
                        </div>
                    </div>
                </div>


                <div className="flex flex-col self-stretch mt-8">
                    <div className="w-full text-3xl font-bold text-zinc-800 max-md:max-w-full">
                        Payment <span className="text-orange-500">Terms</span>
                    </div>
                    <div className="mt-6 w-full text-lg text-zinc-600 max-md:max-w-full">
                        You agree to pay all fees associated with your use of the Service,
                        including fees for paid courses, in accordance with the payment terms
                        specified at the time of purchase.
                        <br /><br/>
                        We may use third-party payment processors to process your payments. You
                        are responsible for complying with the terms of service of any such
                        third-party payment processor.
                        <br /> <br/>
                        Payments shall be made within the timelines that are communicated to
                        you. All payments made herein shall be subject to applicable taxes and
                        non-refundable.
                    </div>
                </div>


                <div className="flex flex-col self-stretch mt-8">
                    <div className="w-full text-3xl font-bold text-zinc-800 max-md:max-w-full">
                        Electronic <span className="text-orange-500">Communication</span>
                    </div>
                    <div className="mt-6 w-full text-lg text-zinc-600 max-md:max-w-full">
                        By using the Service, you agree to receive communications from us
                        electronically, such as emails, newsletters, and push notifications. You
                        may opt out of receiving certain promotional emails by following the
                        unsubscribe instructions in those emails.
                    </div>
                </div>




                    <div className="flex flex-col self-stretch mt-8">
                        <div className="w-full text-3xl font-bold text-zinc-800 max-md:max-w-full">
                            Account <span className="text-orange-500">Termination</span>
                        </div>
                        <div className="flex flex-col p-6 mt-6 w-full text-lg rounded-xl terms_bg_clr text-zinc-600 max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-orange-500 max-md:max-w-full">
                                Reasons for Termination of Account
                            </div>
                            <div className="mt-6 max-md:max-w-full">
                                Headway Business Solutions LLP may terminate your access to the
                                Service for several reasons, including:
                            </div>
                            <div className="mt-6 max-md:max-w-full">
                                <span className="font-bold text-zinc-800">
                                    1. Repeated Copyright Infringement:
                                </span>{" "}
                                    We take copyright infringement seriously. Accounts identified as
                                    repeat offenders will be terminated.
                                    <br />​<br />
                                    <span className="font-bold text-zinc-800">2. Breach of Terms</span>:
                                    Violating any provision of these Terms of Service can lead to account
                                    termination.
                                    <br />​<br />
                                    <span className="font-bold text-zinc-800">
                                        3. Illegal Activity:
                                    </span>{" "}
                                    Using the Service for illegal purposes will result in termination.
                                    <br />​<br />
                                    <span className="font-bold text-zinc-800">
                                        4. Disruption</span>: If
                                    your use disrupts our business operations or negatively impacts other
                                    users, your account may be terminated.
                                    <br />​<br />
                                    <span className="font-bold text-zinc-800">
                                        5. Inappropriate Behavior:
                                    </span>{" "}
                                    Engaging in behaviour considered offensive, unlawful, or potentially
                                    damaging to Headway Business Solutions LLP may lead to account
                                    termination.
                            </div>
                        </div>
                        <div className="flex flex-col p-6 mt-6 w-full rounded-xl terms_bg_clr max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-orange-500 max-md:max-w-full">
                                Content Removal and Termination
                                </div>
                                <div className="mt-6 text-lg text-zinc-600 max-md:max-w-full">
                                We reserve the right to remove content that violates these Terms,
                                including (but not limited to) pornography, obscenity, excessively
                                lengthy content, or anything else we consider inappropriate. We may
                                remove such content and/or terminate accounts associated with it at
                                any time and without notice.
                            </div>
                        </div>
                        <div className="flex flex-col p-6 mt-6 w-full rounded-xl terms_bg_clr max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-orange-500 max-md:max-w-full">
                                Suspension and Password Changes
                            </div>
                            <div className="mt-6 text-lg text-zinc-600 max-md:max-w-full">
                                We may suspend your access to the Service or require a password change
                                if we suspect misuse. We will notify you if this happens. Account
                                termination or suspension does not affect any outstanding liabilities
                                you may have incurred.
                            </div>
                        </div>
                        <div className="flex flex-col p-6 mt-6 w-full text-zinc-600 rounded-xl terms_bg_clr max-md:px-5 max-md:max-w-full">
                                <div className="text-xl font-bold max-md:max-w-full text-orange-500">
                                    Effects of Termination
                                    </div>
                                    <div className="mt-6 text-lg max-md:max-w-full">
                                    1.{" "}
                                    <span className="text-orange-500">
                                        Termination is immediate upon our decision.
                                    </span>
                                    <br />​<br />
                                    2. We reserve the right to withhold any payments due to a terminated
                                    user and only pay for legitimate work not involved in any violation.
                                    <br />​<br />
                                    3. Terminated users lose access to their accounts.
                                </div>
                        </div>
                    </div>


                    <div className="flex flex-col self-stretch mt-8">
                        <div className="w-full text-[32px] font-bold text-zinc-800 max-md:max-w-full ">
                            Copyright Infringement <span className="text-orange-500">Policy</span>
                        </div>
                        <div className="mt-6 w-full text-lg text-zinc-600 max-md:max-w-full">
                            <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                            has a clear copyright policy to address claims of infringement. We take
                            copyright infringement seriously and have procedures for receiving and
                            addressing claims. Accounts identified as repeat copyright infringers
                            (notified more than twice) will be terminated. If you believe your work
                            has been infringed, please refer to our copyright policy for
                            instructions on how to file a claim.
                        </div>
                    </div>


                    <div className="flex flex-col self-stretch mt-8">
                        <div className="w-full text-[32px] font-bold text-zinc-800 max-md:max-w-full">
                            Notice of Copyright{" "}
                            <span className="text-orange-500">
                            Infringement and Confidentiality
                            </span>
                        </div>
                        <div className="flex flex-col p-6 mt-6 w-full text-lg bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-2xl font-bold text-zinc-800 max-md:max-w-full">
                            Copyright Infringement Policy
                            </div>
                            <div className="mt-3 text-zinc-600 max-md:max-w-full">
                            Headway utilizes a &quot;three-strike&quot; policy for copyright
                            infringement:
                            </div>
                            <div className="mt-3 text-zinc-600 max-md:max-w-full">
                            <span className="font-bold text-zinc-800">1. First Offense:</span>{" "}
                            The infringing content is removed, the person responsible receives a
                            warning, and the course creation is suspended for one week.
                            <br />​<br />
                            <span className="font-bold text-zinc-800">
                                2. Second Offense:
                            </span>{" "}
                            The educator is suspended from creating new videos for one month, and
                            all infringing content is removed immediately.
                            <br />​<br />
                            <span className="font-bold text-zinc-800">
                                3.Third Offense:
                            </span>{" "}
                            The educator is permanently banned from the platform and can no longer
                            create courses.
                            </div>
                            </div>
                            <div className="flex flex-col p-6 mt-6 w-full text-lg bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                                <div className="text-2xl font-bold text-zinc-800 max-md:max-w-full">
                                    How To Report Copyright Infringement
                                </div>
                                <div className="mt-3 text-zinc-600 max-md:max-w-full">
                                    If you believe your copyright work has been violated on our platform,
                                    please submit a written notice to our Copyright Agent. The notice
                                    should include:
                                </div>
                                <div className="mt-3 text-zinc-600 max-md:max-w-full">
                                    <span className="font-bold text-zinc-800">1. Your Signature: </span>
                                    Physical or electronic signature of someone authorized to act on the
                                    copyright owner's behalf.
                                    <br />​<br />
                                    <span className="font-bold text-zinc-800">
                                        2. Description of Copyrighted Work:
                                    </span>{" "}
                                    A clear description of the work you claim has been infringed.
                                    <br />​<br />
                                    <span className="font-bold text-zinc-800">
                                        3.Location of Infringing Material:{" "}
                                    </span>
                                    Identify the location of the infringing content on our platform
                                    (educator name, topic, etc.).
                                    <br />​<br />
                                    <span className="font-bold text-zinc-800">
                                        4. Your Contact Information:{" "}
                                    </span>
                                    Include your email address, physical address, and phone number.
                                    <br />​<br />
                                    <span className="font-bold text-zinc-800">
                                        5. Good Faith Belief:
                                    </span>{" "}
                                    A statement that you believe the disputed use is not authorized by the
                                    copyright owner.
                                    <br />​<br />
                                    <span className="font-bold text-zinc-800">
                                        6. Perjury Statement:{" "}
                                    </span>
                                    A statement, under penalty of perjury, confirming the accuracy of the
                                    information provided and your authorization to act on the copyright
                                    owner's behalf.
                                </div>
                            </div>
                            <div className="flex flex-col p-6 mt-6 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                                <div className="text-2xl font-bold text-zinc-800 max-md:max-w-full">
                                Submit your Notice to:
                                </div>
                                <div className="flex gap-5 justify-between mt-3 w-full max-md:flex-wrap max-md:max-w-full ">
                                <div className="flex gap-5 items-center">
                                    <div className="flex justify-center items-center px-3 w-12 h-12 terms_bg_clr rounded-full">
                                        <img
                                            loading="lazy"
                                            src={Images.header_sms}
                                            // src="https://cdn.builder.io/api/v1/image/assets/TEMP/540880478af4c4aa9a5f4ce882fb3f51705b25335cd80ff945aa556b07e10420?apiKey=8e5983036f284a13866aae97819cdb73&"
                                            className="w-6 aspect-square"
                                            alt="Images"
                                        />
                                    </div>
                                    <div className="flex-auto text-lg text-zinc-800">
                                        info@headway.org.in
                                    </div>
                                </div>
                                <div className="flex gap-5 items-center">
                                    <div className="flex justify-center items-center max-md:px-1 md:w-20 xl:px-1.5  xl:w-12 xl:h-12 px-3 terms_bg_clr rounded-full">
                                    <img
                                        loading="lazy"
                                        src={Images.location_pin}
                                        // src="https://cdn.builder.io/api/v1/image/assets/TEMP/4cc6c7dfa9550b5cdc1f63de8aae94bc2f6c7b2369a0214f8e162d516120b56e?apiKey=8e5983036f284a13866aae97819cdb73&"
                                        className="w-8 aspect-square"
                                        alt="Images"
                                    />
                                    </div>
                                    <div className="flex-auto text-lg text-zinc-800 ">
                                    <span className="text-orange-500">Headway Business Solutions LLP </span>, {officeAddress()}
                                    </div>
                                </div>
                                </div>
                            </div>
                    </div>



                    <div className="flex flex-col self-stretch mt-8">
                        <div className="w-full text-[32px] font-bold text-zinc-800 max-md:max-w-full">
                            Confidentiality
                        </div>
                        <div className="mt-6 w-full text-lg text-zinc-600 max-md:max-w-full">
                            During your use of the Service, you may be exposed to confidential
                            information of
                            <span className="text-orange-500">
                            {" "}
                            Headway Business Solutions LLP{" "}
                            </span>
                            (pricing, payment terms, employee details, user lists, business models,
                            etc.). You agree not to disclose this information to any third party
                            without our prior written consent. You acknowledge that such information
                            is our property, and any breach of this agreement will cause us
                            irreparable harm.
                        </div>
                    </div>


                    <div className="flex flex-col self-stretch text-lg text-zinc-600 mt-8">
                        <div className="w-full text-[32px] font-bold text-zinc-800 max-md:max-w-full">
                            DISCLAIMER
                        </div>
                        <div className="mt-6 w-full text-zinc-600 max-md:max-w-full">
                            YOU USE THE SERVICES AT YOUR OWN SOLE RISK.{" "}
                            <span className="text-orange-500">HEADWAY BUSINESS SOLUTIONS LLP</span>,
                            ITS OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS DISCLAIM ALL WARRANTIES,
                            EXPRESS OR IMPLIED, RELATED TO THE SERVICES AND YOUR USE THEREOF.
                        </div>
                        <div className="mt-6 w-full max-md:max-w-full">
                            It includes warranties regarding the accuracy or completeness of content
                            on the platform or linked websites. Headway Business Solutions LLP
                            assumes no liability for:
                        </div>
                        <div className="mt-6 w-full max-md:max-w-full">
                            1. Errors, mistakes, or inaccuracies in User Content or any content on the
                            platform.
                            <br />
                            2. Personal injury or property damage arising from your use of the
                            Services.
                            <br />
                            3. Unauthorized access to or use of our secure servers and/or stored
                            personal or financial information.
                            <br />
                            4. Service interruptions or outages.
                            <br />
                            5. Viruses or other malicious code transmitted through the Services by a
                            third party.
                            <br />
                            6. Errors or omissions in User Content or any other content, or losses
                            incurred from using such content.
                        </div>
                        <div className="mt-6 w-full max-md:max-w-full">
                            <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                            does not endorse, guarantee, or assume responsibility for any
                            third-party products or services advertised on the platform. You should
                            exercise caution and use your best judgment when interacting with
                            third-party providers.
                        </div>
                    </div>


                    <div className="flex flex-col self-stretch text-lg text-orange-500 mt-8">
                        <div className="w-full text-[32px] font-bold max-md:max-w-full text-zinc-800">
                            Limitation of <span className="text-orange-500">Liability</span>
                        </div>
                        <div className="mt-6 w-full max-md:max-w-full text-zinc-600">
                            TO THE FULLEST EXTENT PERMITTED BY{" "}
                            <span className="text-orange-500">
                            LAW, HEADWAY BUSINESS SOLUTIONS LLP
                            </span>
                            , ITS OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS, WILL NOT BE LIABLE FOR
                            ANY DAMAGES ARISING FROM:
                        </div>
                        <div className="mt-6 w-full text-zinc-600 max-md:max-w-full">
                            1. Errors, mistakes, or inaccuracies in User Content or any other content
                            available on the platform.
                            <br />
                            2. Personal injury or property damage arising from your use of the
                            Services.
                            <br />
                            3. Unauthorized access to or use of our secure servers and/or stored
                            personal or financial information.
                            <br />
                            4. Service interruptions or outages.
                            <br />
                            5. Viruses or other malicious code transmitted through the Services by a
                            third party.
                            <br />
                            6. Errors or omissions in User Content or any other content, or losses
                            incurred from using such content.
                        </div>
                        <div className="mt-6 w-full max-md:max-w-full text-zinc-600">
                            This applies regardless of the legal approach used (warranty, contract,
                            tort, etc.) and even if{" "}
                            <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                            is advised of the possibility of such damages. Some jurisdictions may
                            have limitations on excluding warranties or disclaiming liability. In
                            such cases, Headway Business Solutions LLP's liability will be limited
                            to the extent permitted by law, which may include providing the Services
                            again (or the cost of doing so).
                        </div>
                        <div className="mt-6 w-full max-md:max-w-full text-zinc-600">
                            YOU ACKNOWLEDGE THAT{" "}
                            <span className="text-orange-500">HEADWAY BUSINESS SOLUTIONS LLP</span>{" "}
                            IS NOT LIABLE FOR USER CONTENT OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL
                            CONDUCT OF ANY THIRD PARTY. YOU ASSUME ALL RISK OF HARM OR DAMAGE FROM
                            THE FOREGOING.
                        </div>
                    </div>


                        <div className="flex flex-col self-stretch text-lg text-zinc-600 mt-8">
                            <div className="w-full text-[32px] font-bold max-md:max-w-full text-zinc-800">
                                Jurisdiction and <span className="text-orange-500">Governing Law</span>
                            </div>
                            <div className="mt-6 w-full max-md:max-w-full text-zinc-600">
                                The Services are controlled and offered by{" "}
                                <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                                from its facilities in India. The Services may not be appropriate for
                                use in other locations. If you access the Services from another
                                jurisdiction, you do so at your own risk and are responsible for
                                complying with local laws.
                            </div>
                            <div className="mt-6 w-full text-zinc-600 max-md:max-w-full">
                                These Terms of Service are governed by and construed in accordance with
                                the laws of India, without reference to conflict of laws principles. You
                                irrevocably submit to the exclusive jurisdiction of the courts located
                                in Ahmedabad, Gujarat, India.
                            </div>
                        </div>


                        <div className="flex flex-col self-stretch text-lg text-zinc-600 mt-8">
                                <div className="w-full text-[32px] font-bold text-zinc-800 max-md:max-w-full">
                                    Indemnification
                                </div>
                                <div className="mt-6 w-full text-zinc-600 max-md:max-w-full">
                                    You agree to defend, indemnify, and hold harmless{" "}
                                    <span className="text-orange-500">Headway Business Solutions LLP</span>,
                                    its parent corporation, officers, directors, employees, and agents, from
                                    any claims, damages, losses, liabilities, costs, or expenses arising
                                    from:
                                </div>
                                <div className="mt-6 w-full max-md:max-w-full text-zinc-600">
                                    <div className='flex items-center'>
                                    <li></li>Your use of the Service.
                                    </div>
                                    <div className='flex items-center'>
                                    <li></li>Your violation of these Terms of Service.
                                    </div>
                                    <div className='flex items-center'>
                                    <li></li>Your violation of any third-party right.
                                    </div>
                                    <div className='flex items-center'>
                                    <li></li>A claim that your User Content caused damage to a third party.
                                    </div>
                                    <div className='flex items-center'>
                                    <li></li>Your violation of any applicable laws.
                                    </div>
                                    
                                </div>
                                <div className="mt-6 w-full max-md:max-w-full text-zinc-600">
                                    This obligation will survive these Terms of Service and your use of the
                                    Service.
                                </div>
                        </div>


                        <div className="flex flex-col self-stretch text-zinc-600 mt-8">
                                <div className="w-full text-[32px] font-bold max-md:max-w-full text-zinc-800">
                                    Eligibility and Acceptance{" "}
                                    <span className="text-orange-500">of Terms</span>
                                </div>
                                <div className="mt-6 w-full text-lg max-md:max-w-full">
                                    You confirm that you are at least 18 years old (or have
                                    parental/guardian consent) and are fully able to enter into these Terms
                                    of Service.{" "}
                                    <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                                    reserves the right to refuse access to the Services or to
                                    suspend/terminate existing access at any time without reason. These
                                    Terms of Service govern your use of the platform. You acknowledge that
                                    you have been given a reasonable opportunity to review and agree to
                                    them. Your use of the Services constitutes your agreement to the most
                                    current version of the Terms of Service available on the platform.
                                </div>
                        </div>


                        <div className="flex flex-col self-stretch mt-8">
                                <div className="w-full text-[32px] font-bold text-zinc-800 max-md:max-w-full">
                                    Force <span className="text-orange-500">Majeure</span>
                                </div>
                                <div className="mt-6 w-full text-lg text-zinc-600 max-md:max-w-full">
                                    <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                                    will not be liable for its failure to perform its obligations if such
                                    failure is caused by events beyond its reasonable control, including
                                    acts of God, war, terrorism, natural disasters, strikes, epidemics,
                                    civil unrest, power outages, or severe weather.
                                </div>
                        </div>


                        <div className="flex flex-col self-stretch mt-8">
                                <div className="w-full text-[32px] font-bold text-zinc-800 max-md:max-w-full">
                                    Information Technology <span className="text-orange-500">Act</span>
                                </div>
                                <div className="mt-6 w-full text-lg text-zinc-600 max-md:max-w-full">
                                    Indian law prohibits posting malicious, defamatory, inappropriate,
                                    slanderous, or sexually oriented content, or content that attacks
                                    individuals, groups, institutions, or companies. You also agree not to
                                    post information without the necessary copyrights or permissions.
                                    Failure to comply with these terms may result in the removal of your
                                    postings without notice.
                                </div>
                        </div>


                        <div className="flex flex-col self-stretch mt-8">
                                <div className="w-full text-[32px] font-bold text-zinc-800 max-md:max-w-full">
                                    Data Retention{" "}
                                    <span className="text-orange-500">and Applicable Laws</span>
                                </div>
                                <div className="mt-6 w-full text-lg text-zinc-600 max-md:max-w-full">
                                    <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                                    is subject to various Indian laws and regulations governing data
                                    retention and privacy. We will maintain data related to the Services,
                                    Goods, and personal information in a format compliant with these laws.{" "}
                                    <span className="text-orange-500">Headway Business Solutions LLP</span>{" "}
                                    reserves the right to use this information to the extent necessary to
                                    comply with such laws, and as may be directed or amended from time to
                                    time.
                                </div>
                        </div>



                        <div className="flex flex-col self-stretch mt-8">
                                <div className="w-full text-[32px] font-bold text-zinc-800 max-md:max-w-full">
                                    Governing Law{" "}
                                    <span className="text-orange-500">and Dispute Resolution</span>
                                </div>
                                <div className="mt-6 w-full text-lg text-zinc-600 max-md:max-w-full">
                                    These Terms of Service are governed by and construed in accordance with
                                    the laws of India, without regard to its conflict of law principles. You
                                    irrevocably and unconditionally submit to the exclusive jurisdiction of
                                    the courts located in Ahmedabad, Gujarat, India for the resolution of
                                    any dispute arising out of or relating to these Terms of Service or your
                                    use of the Services.
                                </div>
                        </div>




                        <div className="flex flex-col self-stretch text-lg mt-8">
                                <div className="w-full text-[32px] font-bold text-zinc-800 max-md:max-w-full">
                                    Closure
                                </div>
                                <div className="mt-6 w-full text-zinc-600 max-md:max-w-full">
                                    If any portion of these Terms of Service is considered unlawful, void,
                                    or unenforceable, that particular provision shall be considered
                                    severable, and it shall not affect the validity or enforceability of the
                                    remaining provisions. Any notices required to be given concerning the
                                    Services must be provided in writing to the registered office of{" "}
                                    <span className="text-orange-500">Headway Business Solutions LLP</span>.
                                    We cannot guarantee continuous, uninterrupted, or secure access to our
                                    Service, as factors beyond our control may interfere with its operation.
                                </div>
                                <div className="mt-6 w-full text-zinc-600 max-md:max-w-full">
                                    THE HEADINGS USED HEREIN ARE FOR REFERENCE PURPOSES ONLY AND DO NOT
                                    DEFINE, LIMIT, CONSTRUE, OR DESCRIBE THE SCOPE OR EXTENT OF ANY SECTION.
                                    OUR FAILURE TO ACT IN RESPONSE TO ANY BREACH BY YOU OR OTHERS DOES NOT
                                    WAIVE OUR RIGHT TO ACT REGARDING SUBSEQUENT OR SIMILAR BREACHES.
                                </div>
                        </div>










            </div>
        </div>
    )
}


export default TCContent        