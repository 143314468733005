// Import the functions you need from the SDKs you need
 import { initializeApp } from "firebase/app";
 import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyBVxwIRObCoOvDeQ-R9FUJfevwgSRZtBDg",
  authDomain: "headwaywebsite-da968.firebaseapp.com",
  projectId: "headwaywebsite-da968",
  storageBucket: "headwaywebsite-da968.appspot.com",
  messagingSenderId: "265131377167",
  appId: "1:265131377167:web:665a27cbd9e89c6048997a",
  measurementId: "G-HSEMWE2PRZ"
};

// Initialize Firebase
 const app = initializeApp(firebaseConfig);
 const analytics = getAnalytics(app);

 export { app, analytics };


// firebase.initializeApp(firebaseConfig);
// firebase.analytics();

// export default firebase;
// firebase.initializeApp(firebaseConfig);
// const analytics = firebase.analytics();
// export default analytics;