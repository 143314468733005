import React from 'react';
import { LinkTagwithClass } from '../../Link';
import * as Images from '../../../assets';
import { ButtonOrange } from '../../Buttons';
import UpdatedAnimatedComponent from '../../AnimationWrapperComponent';
import { Link } from 'react-router-dom';

const EventNewsletter = ({ titleRef, isVisible }) => {
    return (
        <>

            <div className="flex flex-col justify-center w-full max-md:max-w-full terms_bg_clr " ref={titleRef}>
                <div className="flex overflow-hidden relative flex-col justify-center items-center px-16 py-12 w-full fill-stone-50 max-md:px-5 max-md:max-w-full">
                    <img
                        loading="lazy"
                        src={Images.about_us_vector}
                        className="object-cover absolute top-0 "
                        alt=''
                    />
                    <div className=" my-6 max-w-full w-[1296px] z-10 ">
                        <div className=" items-center flex justify-between gap-5 max-md:gap-0 max-md: bg-white pe-10  max-sm:px-3 ">
                        <img
                                loading="lazy"
                                // srcSet="..."
                                src={Images.event_newsletter}
                                // className=' xl:w-5/12 right-0 mt-[-6%] bg-white md:w-1/3 sm:w-1/2  sm:right-[5%]  w-2/5 '
                                // // className="object-cover absolute inset-0 size-full"
                                className=" xl:w-5/12 right-0 mt-[-6%] md:w-1/3 sm:w-1/2  sm:right-[5%]  w-2/5 "
                                alt=''
                            />
                            <div 
                            // className="text-wrap  mt-1 font-semibold md:text-xl sm:text-base sm:right-[18%] md:right-[18%] xl:top-[25%] xl:right-[22%] xs:right-[5%] lg:right-[18%] lg:top-[19%] lg:text-2xl xl:text-4xl max-sm:right-[16%] max-sm:text-xs max-sm:top-[18%] md:top-[10%] sm:top-[10%]"
                            >
                                <div
                                    // className="flex flex-col font-medium max-w-[660px]"
                                    className='flex flex-col font-medium items-center '
                                >
                                    <h2 className="w-full text-5xl max-md:max-w-full max-md:text-4xl max-lg:text-4xl max-xl:text-5xl 2xl:text-5xl xl:text-4xl lg:text-2xl md:text-2xl md:m-0  sm:text-lg xs:text-base ">
                                        <span
                                            // className="leading-[58px] text-zinc-800 border"
                                            className="text-zinc-800  2xl:leading-[58px] xl:leading-[58px] lg:leading-[58px] md:leading-[48px]"
                                        >Sign up for </span>
                                        <span
                                            // className="font-bold text-orange-500 leading-[58px] border"
                                            className="font-bold text-orange-500 "
                                        >
                                            Our Newsletter
                                        </span>
                                    </h2>
                                    <div className="mt-3 w-full text-lg text-zinc-600 text-wrap max-md:max-w-full max-lg:text-base 2xl:text-lg 2xl:mt-4 lg:mt-1 lg:text-sm md:mt-0 md:text-xs sm:text-[10px] sm:mt-0">
                                        Be the first one to get updates and Special events or seminar
                                    </div>
                                    <form className="flex gap-5 justify-between self-start p-4 mt-6 rounded-lg shadow-md terms_bg_clr 2xl:mt-6 lg:p-2 max-lg:mt-4 lg:mt-2 md:mt-1 md:p-2 sm:mt-0 sm:p-1">
                                        <div
                                            // className="flex flex-col flex-1 "
                                            className=' flex flex-col flex-1 '
                                        >
                                            {/* <label htmlFor="email" className="text-sm text-zinc-600 2xl:text-base lg:text-xs">
                                    Type your Email Address
                                </label> */}
                                            <div className="text-sm text-zinc-600 2xl:text-sm lg:text-xs md:text-[10px] sm:text-[8px] ">
                                                Type your Email Address
                                            </div>
                                            {/* <input
                                    type="email"
                                    id="email"
                                    className="mt-3 text-base border-0 terms_bg_clr focus:outline-0 text-zinc-800"
                                    placeholder="terrycalzoni@gmail.com"
                                    aria-label="Type your Email Address"
                                /> */}
                                            <div className=' text-base text-black mt-1 2xl:text-base 2xl:mt-1 lg:text-sm md:mt-0 md:text-xs sm:text-[10px] sm:m-0'>terrycalzoni@gmail.com</div>
                                        </div>
                                        <button
                                            type="submit"
                                            className="grow justify-center px-8 py-3.5 my-auto text-base text-white text-wrap bg-orange-500 rounded-lg max-md:px-5 2xl:text-base 2xl:px-8 2xl:py-3.5 max-lg:text-sm max-lg:px-4 lg:py-1 lg:px-2 lg:text-xs md:text-sm md:px-2 md:py-1 sm:p-1 sm:text-[10px] sm:py-0"
                                        >
                                            Send Now
                                        </button>
                                    </form>
                                </div>
                            </div>


                            {/* <div className='border border-green-500 w-full'> */}
                            
                            {/* </div> */}

                        </div>
                    </div>
                </div>
            </div>
 
        </>
    )
}

export default UpdatedAnimatedComponent(EventNewsletter)